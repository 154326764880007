import React from 'react'
import './css/AboutUs.css'

const AboutUs = () => {
    return (
        <>
            <div className="home-page-welcome" style={{ backgroundImage: "url(assets/images/bgnew.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-12 order-2 order-lg-1">
                            <div className="welcome-content">
                                <header className="entry-header">
                                    <h2 className="entry-title">
                                        WELCOME TO OUR LADY OF FATIMA SHRINE <br />
                                        KRISHNAGIRI
                                    </h2>
                                </header>
                                <div className="entry-content mt-2">
                                    Almighty Lord! We are praising us We are.  Thanks to Um “daughter Treat hail with all encouragement.  Thank you for your promise of being with you. New my temple. We are happily there Who said drink drink! We, your dear people, stay in your presence Participate in Ummod and Closeup  Become a new beloved Mr This project is a form  renewal process  We dedicate your pure revision.
                                </div>
                                <div className="containernewbox">
                                    <h2 className="quotehead">“Do not be afraid. I will never forsake you. My Immaculate Heart will be your refuge and the way that will lead you to God.”</h2>
                                    <p className="quote"> Our Lady of Fatima</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default AboutUs
